import { StyledTextField as TextField } from '@views/Prescriptions/Prescriptions.style'
import React from 'react'
import styles from './styles.module.scss'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'

type IssueDoctorInputProps = {
  name: string
  message?: string
  required?: boolean
  label: string
  value?: string
  onChange?: (value: string) => void
  hasError?: boolean
  isValid?: boolean
  isPristine?: boolean
  errorText?: string
}

export function DoctorNameInput(props: IssueDoctorInputProps) {
  const { value, onChange, hasError, isValid, isPristine, label, message, required } = props

  const { requireBasicInfo } = useRxTaxExemption()

  if (requireBasicInfo) {
    return null
  }

  return (
    <div className={styles.inputField}>
      <TextField
        value={value ?? null}
        label={label}
        onChange={v => {
          if (onChange) {
            onChange(v.target.value)
          }
        }}
        required={required}
        error={hasError}
        helperText={message}
        isvalid={isValid}
        ispristine={isPristine}
        showvalidationstatus
      />
    </div>
  )
}
