import React from 'react'
import { IssueDateInput } from './IssueDateInput'
import { useTranslation } from 'next-i18next'
import { DoctorNameInput } from './DoctorNameInput'
import { Controller, useFormContext } from 'react-hook-form'
import { isEmpty } from 'lodash-es'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'
import styles from '@components/PrescriptionDetailsForm/styles.module.scss'
import { TitleTooltip } from './TitleTooltip'
import clsx from 'clsx'

type PrescriptionDetailsProps = {
  direction?: 'row' | 'column'
}

export function PrescriptionDetailsForm(props: PrescriptionDetailsProps) {
  const { direction = 'row' } = props
  const { t } = useTranslation(undefined, { keyPrefix: 'PrescriptionDetailsForm' })
  const { control } = useFormContext()
  const { isEnabled } = useRxTaxExemption()

  if (!isEnabled) {
    return null
  }

  return (
    <div className={styles.container}>
      <p className={styles.description}>
        {t('PrescriptionDetails')}
        <TitleTooltip body={t('ExemptionInfo')} />
      </p>

      <div
        className={clsx({
          [styles.formFields]: true,
          [styles.row]: direction === 'row',
          [styles.column]: direction === 'column',
        })}
      >
        <Controller
          name="issue"
          control={control}
          render={({ field, fieldState: { error } }) => {
            const hasError = (error && !!field.value?.length) || (error && isEmpty(field.value))
            const dateTime = new Date(field.value).getTime()
            const isValid = !error && !isNaN(dateTime)
            const isPristine = !field.value?.length
            const errorText = hasError ? t('Required') : ''

            return (
              <IssueDateInput
                name="issue"
                label={t('DateOfPrescription')}
                message={errorText}
                required
                onChange={field.onChange}
                isValid={isValid}
                isPristine={isPristine}
                hasError={hasError}
                value={field.value ?? null}
              />
            )
          }}
        />
        <Controller
          name="doctor"
          control={control}
          render={({ field, fieldState: { error } }) => {
            const hasError = (error && !!field.value?.length) || (error && isEmpty(field.value))
            const isValid = !error && !!field.value?.length
            const isPristine = !field.value?.length
            const errorText = hasError ? t('Required') : ''

            return (
              <DoctorNameInput
                name="doctor"
                value={field.value ?? null}
                label={t('DoctorName')}
                message={errorText}
                required
                onChange={field.onChange}
                isValid={isValid}
                isPristine={isPristine}
                hasError={hasError}
              />
            )
          }}
        />
      </div>
    </div>
  )
}
