import { doneEditingCartItem } from '@features/cartui/cartuiSlice'
import { CART } from '@constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useCreatePrescriptionDetailsMutation } from '@features/order/query'
import { productContactLensesDataSelector, prescriptionDetailsSelector } from '@features/product/selector'
import { CartOrder } from '@features/order/thunks/addContactLenses'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'

type OnContactLensAddToCartProps = {
  order?: CartOrder
}

type OnContactLensAddToCart = (props: OnContactLensAddToCartProps) => void

export function useHandleContactLensCallback() {
  const router = useRouter()
  const dispatch = useDispatch()
  const { langCode } = useStoreIdentity()
  const [createPrescriptionDetails] = useCreatePrescriptionDetailsMutation()
  const productDetails = useSelector(prescriptionDetailsSelector)
  const contactLensData = useSelector(productContactLensesDataSelector)
  const rxTaxExemption = useRxTaxExemption()

  const onContactLensAddToCart: OnContactLensAddToCart = async data => {
    const { order } = data
    if (!order) return

    if (rxTaxExemption.isEnabled && productDetails) {
      await createPrescriptionDetails({
        orderId: order.orderId,
        orderItemId: (order.orderItem ?? []).map(item => item.orderItemId).join(','),
        prescription: {
          issue: productDetails.issue,
          doctor: productDetails.doctor,
          productType: 'CL',
          nickName: '',
          firstName: '',
          lastName: '',
          telephone: '',
          dateOfBirth: '',
          prescriptionState: '',

          rightBaseCurve: contactLensData?.right?.['x_baseCurve'],
          rightDiameter: contactLensData?.right?.['x_diameter'],
          rightPrismPower: contactLensData?.right?.['x_spherePower'],

          leftBaseCurve: contactLensData?.left?.['x_baseCurve'],
          leftPrismPower: contactLensData?.left?.['x_spherePower'],
          leftDiameter: contactLensData?.left?.['x_diameter'],
        },
      }).unwrap()
    }

    dispatch(doneEditingCartItem())

    router.push(`/${langCode}/${CART}`)
  }

  return { onContactLensAddToCart }
}
