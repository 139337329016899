import React from 'react'
import useTheme from '@mui/material/styles/useTheme'
import {
  StyledHeaderStepsBar,
  StyledHeaderStepsBarContainer,
  StyledHeaderStepsBarElement,
  StyledHeaderStepsBarElementDot,
  StyledHeaderStepsBarElementLine,
  StyledHeaderStepsBarElementStepname,
  StyledHeaderStepsBarElementWrapper,
} from './HeaderStepsBar.style'
import useBreakpoints from '@hooks/useBreakpoints'
import { useRouter } from 'next/router'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

export interface Step {
  stepId: string
  stepNumber: number
  stepTitle: string
  url: string
}

export interface StepsProgress {
  activeStep?: string
  previousSteps: Step[]
  nextSteps: Step[]
  allSteps: Step[]
}

export interface HeaderStepsBarProps {
  stepState: StepsProgress
}

export const HeaderStepsBar: React.FC<HeaderStepsBarProps> = ({ stepState }) => {
  const { activeStep, previousSteps, allSteps } = stepState
  const { isMobile } = useBreakpoints()
  const router = useRouter()
  const theme = useTheme()

  return (
    <StyledHeaderStepsBarContainer>
      <StyledHeaderStepsBar>
        {allSteps.map((step, index) => {
          const isActive = step.stepId === activeStep ? 1 : 0
          const isCompleted = previousSteps.includes(step) ? 1 : 0
          const isLastStep = index + 1 === allSteps.length

          return (
            <StyledHeaderStepsBarElement
              key={step.stepId}
              active={isActive}
              completed={isCompleted}
              last={isLastStep ? 1 : 0}
            >
              <StyledHeaderStepsBarElementWrapper
                completed={isCompleted}
                onClick={() => {
                  if (isCompleted) {
                    console.debug('[DEBUG] ~ HeaderStepsBar.tsx ~ StyledHeaderStepsBarElementWrapper ~ onClick')
                    router.push(`${allSteps[index].url}`, undefined, { shallow: true })
                  }
                }}
              >
                <StyledHeaderStepsBarElementDot completed={isCompleted} active={isActive}>
                  {isCompleted === 1 && (
                    <SVGIcon library="validation" name="validate" color={theme.palette.custom.cyprus} />
                  )}
                  {isMobile && (
                    <StyledHeaderStepsBarElementStepname>{step.stepTitle}</StyledHeaderStepsBarElementStepname>
                  )}
                </StyledHeaderStepsBarElementDot>
                {!isMobile && (
                  <StyledHeaderStepsBarElementStepname>{step.stepTitle}</StyledHeaderStepsBarElementStepname>
                )}
              </StyledHeaderStepsBarElementWrapper>
              {!isLastStep && <StyledHeaderStepsBarElementLine completed={isCompleted} />}
            </StyledHeaderStepsBarElement>
          )
        })}
      </StyledHeaderStepsBar>
    </StyledHeaderStepsBarContainer>
  )
}
