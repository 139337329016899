import { StyledTooltip } from '@components/UI'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import React from 'react'

type ExemptionTooltipProps = {
  body: string
}

export function TitleTooltip(props: ExemptionTooltipProps) {
  const { body } = props
  return (
    <StyledTooltip
      contentVariant={{
        type: 'textonly',
        body,
      }}
      clickable
      colorVariant={'darkgray'}
      placement={'top'}
    >
      <span>
        <SVGIcon library="validation" name="info" color={'#000'} />
      </span>
    </StyledTooltip>
  )
}
