import config from '@configs/index'
import { useSite } from '@foundation/hooks/useSite'

export function useRxTaxExemption() {
  const { mySite } = useSite()
  const countryConfig = config.rxTaxExemptionFields[mySite.country] || {}

  return {
    isEnabled: countryConfig.enabled ?? false,
    requireBasicInfo: (countryConfig.enabled && countryConfig.requireBasicInfo) ?? false,
    showFullForm: (countryConfig.enabled && !countryConfig.requireBasicInfo) ?? false,
  }
}
