import { StyledTextField as TextField } from '@views/Prescriptions/Prescriptions.style'
import { DatePicker } from '@components/UI'
import styles from './styles.module.scss'

type IssueDateInputProps = {
  name: string
  message?: string
  required?: boolean
  label: string
  value?: Date
  onChange?: (date: Date) => void
  hasError?: boolean
  isValid?: boolean
  isPristine?: boolean
  errorText?: string
}

export function IssueDateInput(props: IssueDateInputProps) {
  const { message, required, label, value, onChange, hasError, isValid, isPristine } = props

  return (
    <div className={styles.inputField}>
      <DatePicker
        views={['year', 'month', 'day']}
        value={value ?? null}
        onChange={data => {
          if (onChange && data) {
            onChange(data)
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            required={required}
            error={hasError}
            helperText={message}
            isvalid={isValid}
            ispristine={isPristine}
            showvalidationstatus
          />
        )}
        label={label}
      />
    </div>
  )
}
